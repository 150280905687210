import * as Sentry from '@sentry/sveltekit';

Sentry.init({
	dsn: 'https://18de871af76864face3a685344cf3481@o4507898974896128.ingest.us.sentry.io/4507898978566144',

	// We recommend adjusting this value in production, or using tracesSampler
	// for finer control
	tracesSampleRate: 1.0,

	// Optional: Initialize Session Replay:
	integrations: [Sentry.replayIntegration({
		// Additional SDK configuration goes in here, for example:
		maskAllText: false,
		blockAllMedia: false,
	  })],
	replaysSessionSampleRate: 1.0,
	replaysOnErrorSampleRate: 1.0
});

const myErrorHandler = ({ error, event }) => {
  console.error("An error occurred on the client side:", error, event);
};

export const handleError = Sentry.handleErrorWithSentry(myErrorHandler);

// or alternatively, if you don't have a custom error handler:
// export const handleError = Sentry.handleErrorWithSentry();
